<template>
    <section class="content">
            <div class="card card-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-9">
                            <div class="row form-horizontal">
                                <div class="col-auto">
                                    <label class="control-label my-2 ml-2">
                                        <h6>Filter Pencarian</h6>
                                    </label>
                                </div>
                                <div class="col-auto mb-2">
                                    <div class="input-group">
                                        <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                                        <div class="input-group-append">
                                            <div class="input-group-text" style="background-color: #fff">
                                                <span><span class="fa fa-calendar"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto mb-2">
                                    <button class="btn dropdown-icon dropdown-toggle" v-bind:class="btnFilter"
                                        @click="selectStatus">Status</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover" ref="tblkewajaran">
                            <thead>
                                <tr>
                                    <th>No. AWB</th>
                                    <th>Status</th>
                                    <th>Tanggal Order</th>
                                    <th>Nilai COD/Paket</th>
                                    <th>Estimasi Diterima</th>
                                    <th>Nilai Diterima (Bospay)</th>
                                    <th>Selisih</th>
                                    <th>Selisih lebih besar dari</th>
                                    <th>Selisih lebih kecil dari</th>
                                </tr>
                            </thead>
                            <tbody @click="handleClick"></tbody>
                        </table>
                    </div>
                </div>
            </div>
    	<Historyfilter :show="show" :sTicket="false" :sCod="false" :sOther="false" v-model="filter" v-on:change="updateFilter" />
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, openChat, formatCurrency} from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import FormClaim from "@/dialog/FormClaim";
import FormClosingClaim from "@/dialog/FormClosingClaim";
import moment from "moment";
import "icheck-material";
export default {
	name: "Kewajaran",
	data() {
		return {
			dateStart: '',
			dateEnd: '',
			errors: [],
			method: "",
			roles: "",
			groups: [],
			formTitle: "Tambah User",
			form: {
				name: "",
				username: "",
				email: "",
			},
			groupId: '',
			downloading: false,
			show: false,
			filter: {},
			// data_type: "pickupTime",
			dt1: moment().startOf("month"),
			dt2: moment(),
			selectedCount: 0,
			totalItems: 0,
			totalPages: 0,
			isOpen: false,
			printBtnMessage: "",
			courier: [],
			urlDownload: "",
			downloading: false,
			templateOpt: 5,
			selectedItems: [],
			btnFilter: 'btn-default',
			deleting: false,
			processing: false,
			openClaim: false,
			showClosingClaim: false,
			waLink: '',
			loadingContent: false,
            trackingStatus: 0,
		};
	},
	computed: {
		kurirLogo() {
			const fileName = "jne"; //logo.toLowerCase()
			return require(`../assets/img/${fileName}.png`); // the module request
		},
	},
	components: {
		Historyfilter
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
        this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
        this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;

	},
	methods: {
        reloadData: function (e) {
            this.table.api().ajax.reload();
		},
		updateFilter: function (e) {
            this.filter = e;
			this.table.api().ajax.reload();
		},
		onDate() {
			this.table.api().ajax.reload();
		},
		selectStatus: function () {
			this.show = !this.show;
		},
		getKurirLogo(kurir) {
			const fileName = kurir.toLowerCase();

			return require(`../assets/img/${fileName}.png`); // the module request
		},
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/order/detail/" + e.target.dataset.id
				});
                window.open(route.href,"_blank");
				// return false;
			}
			else if (e.target.matches(".link-chat")) {
				openChat(e.target.dataset.to);
				return false;
			}
		},
	},
	mounted() {
		const e = this.$refs;
		const self = this;

		new Daterangepicker(
			this.$refs.daterange, {
				startDate: this.dt1,
				endDate: this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				// self.data_type = self.data_type;
				// self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		var filterBy = [0,4,5,6,7,8];
		var role_id =  this.userInfo.role_id;
		this.table = createTable(e.tblkewajaran, {
			title: "",
			ajax: "/report/kewajaran",
			frame: false,
			toolbarEl: e.toolbar,
			filter: true,
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: filterBy,
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			"order": [
				[2, 'desc']
			],
			columns: [
				{
					data: "no_resi"
				},
                {
					data: "tracking_status",
					render: function (data, type, row, meta){
						var badge = '';
						if (row.tracking_status == 0){
							if (row.order_type == 1){
								badge = '<span class="badge badge-secondary">Menunggu Drop</span>';
							} else {
								badge = '<span class="badge badge-secondary">Menunggu Pickup</span>';
							}
						} else if (row.tracking_status == 1){
                            badge = '<span class="badge badge-warning">Proses</span>';
						} else if (row.tracking_status == 2){
                            badge = '<span class="badge badge-success">Terkirim</span>';
						} else if (row.tracking_status == 3){
                            badge = '<span class="badge badge-warning">Proses Retur</span>';
                        }else if (row.tracking_status == 4){
                            if (row.return_received == 1){
                                badge = '<span class="badge badge-success">Retur Diterima</span>';
							} else{
                                badge = '<span class="badge badge-info">Retur Diserahkan</span>';
							}
                        } else if (row.tracking_status == 5) {
                            badge = '<span class="badge badge-danger">Bermasalah</span>';
                        }
						return badge;
					},
				},
				{ data: "ordered_on", render: function (data, type, row, meta) {
                    return moment(data).format("DD/MM/YYYY");
                }, },
				{ 
                    data: "item_value", 
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return 'Rp ' + formatCurrency(data);
                    }
                },
				{ 
                    data: "grand_total", 
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return 'Rp ' + formatCurrency(data);
                    }
                },
                { 
                    data: "total_bospay", 
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return 'Rp ' + formatCurrency(data);
                    }
                },
                {
                    data: "selisih", 
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return 'Rp ' + formatCurrency(data);
                    }
                },
                {
                    data: "selisih", 
                    class:'text-right',
                    visible: false,
                    render: function(data, type, row, meta) {
                        return 'Rp ' + formatCurrency(data);
                    }
                },
                {
                    data: "selisih", 
                    class:'text-right',
                    visible: false,
                    render: function(data, type, row, meta) {
                        return 'Rp ' + formatCurrency(data);
                    }
                }
			],
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
			rowCallback: function (row, data) {
			}
		});
	},
};
</script>
<style scoped>
.dataTables_length{padding-top: 0.5em;}
</style>